<template>
  <div class="demo-inline-spacing">
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      type="submit"
      v-if="showSaveAndClose"
      @click.prevent="onClickSaveAndClose"
      size="sm">
      <feather-icon
        icon="SaveIcon"
        class="mr-50"
        size="16"
      />
      {{ $t('Save and Close') }}
    </b-button>

    <b-button
      variant="warning"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :disabled="$store.getters.getLoader"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      type="submit"
      @click.prevent="onClickSave">
      <b-spinner
        v-if="$store.getters.getLoader"
        class="mr-1"
        small
        variant="light"
      />
      <feather-icon
        v-if="!$store.getters.getLoader"
        icon="SaveIcon"
        class="mr-50"
        size="16"
      />
      {{ $t('Save') }}
    </b-button>

    <b-button
      variant="outline-secondary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      @click="onClickClose"
      v-if="showClose"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'">
      <feather-icon
        icon="XIcon"
        class="mr-50"
        size="16"
      />
      {{ $t('Close') }}
    </b-button>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import router from '@/router';

export default {
  directives: {
    Ripple,
  },
  props: {
    onClickSaveAndClose: {
      type: Function,
      default() {
        return {}
      },
    },

    onClickSave: {
      type: Function,
      default() {
        return {}
      },
    },

    onClickClose: {
      type: Function,
      default() {
        router.go(-1)
      },
    },

    showSaveAndClose: {
      type: Boolean,
      default() {
        return true;
      },
    },
    showClose: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
}
</script>

<style scoped>

</style>
